import React, { useState } from 'react'
import './Navbar.css'
import Logo from './Logo';
import Burger from './burger';
import { HashLink } from 'react-router-hash-link';

function Navbar({menuItems,instaHandle, facebookHandle}) {
    const [clicked, setClicked] = useState(false);
    const toggleClick = ()=> {setClicked(!clicked)}
   
    return (
        <div id="navbar" className={clicked ? `navbar-container heading-clicked` : `navbar-container`}>
            <div className={`heading`}>
                <div onClick={toggleClick} className={'menu-icon'}>
                    <Burger clicked={clicked}></Burger>
                </div> 
                <Logo></Logo>    
            </div>
            
            <div className={clicked ? 'clicked navbar' : 'navbar'}>
            
                <div className='navbar-content'>
                    {menuItems.map((item, index)=>{
                        return(
                        <HashLink to={item.url} className='navbar-item' key={index}>{item.name}</HashLink>)
                        
                    })}
                </div>
                {/* <div className='socials'>
                    <i className="fab fa-instagram"></i>
                    <i className="fab fa-facebook-f"></i>
                </div> */}
            </div>     
        </div>
    )
}

export default Navbar