import React from 'react'
import Countdown from 'react-countdown';
import "./Hero3.css";

function Hero2({smallText, largeText,bgImgUrl, children}) {
    const scrollFunct = () =>{
        // let hero_main_text = document.getElementById('hero_main_text')
        let stars = document.getElementById('stars')
        let comets = document.getElementById('comets')
        let planet1 = document.getElementById('planet1')
        let planet2 = document.getElementById('planet2')
        let planet3 = document.getElementById('planet3')
        let value=window.scrollY;
        
        //Animating header objects
        stars.style.left = value * 0.25 +'px';

        
        comets.style.right = value * 0.25 +'px';
        planet1.style.bottom = -275 + (value * 0.25) +'px';
        planet2.style.bottom = 100 + (value * 0.25) +'px';
        planet3.style.bottom = -180 + (value * 0.15) +'px';
    }

    window.addEventListener('scroll', scrollFunct)
    
    return (
        <div className="hero">
            <div className="hero-content" >
                <h2 id="hero_sub_text">{smallText}</h2>
                <h1 id="hero_main_text">{largeText}</h1>
                {/* <div className="countdown"><Countdown date={new Date('2022-12-26T12:30:00')} /></div> */}
                {/* <div className="slogan"><span>Licensable, tradable, collectable</span></div> */}
            </div>
            <img src={'./media/stars.png'} alt="stars" id={"stars"}/>
            <img src={'./media/parallax2/nebula.png'} alt="nebula" id={"nebula"}/>
            <img src={'./media/parallax2/comets.png'} alt="comets" id={"comets"}/>
            <img src={'./media/parallax2/planet2.png'} alt="planet" id={"planet1"}/>
            <img src={'./media/parallax2/planet2.png'} alt="planet" id={"planet2"}/>
            <img src={'./media/parallax2/planet2.png'} alt="planet" id={"planet3"}/>
        </div>
    )
}

Hero2.defaultProps = {
    contentSide: 'right',
    bgImgUrl: '/media/home-hero.jpg',
    largeText: 'this is Large',
    smallText: 'This is small text, whilst',
    btnStyle: 'btn-border',
    btnSize: 'btn-medium',
    btnEnabled: false
}

export default Hero2