import './Card.css'

import React from 'react'

function Card({cardTitle,cardText,contentsSide}) {
  return (
    <div data-aos='zoom-in-up' className={`card ${contentsSide}`} >
        <div className='card_title'>
            {cardTitle}
        </div>
        <div className='card_content'>
            {cardText}
        </div>
        {/* <Button2 btnStyle={'btn-border'} btnSize={'btn-small'}></Button2> */}
    </div>
  )
}

Card.defaultProps={
    cardTitle: 'The Title',
    cardText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer enim ipsum, dictum at dolor eu, pharetra porttitor lacus.',
    contentsSide:'left'
}

export default Card