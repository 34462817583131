import './Card_image.css'
import React from 'react'

function CardImage({cardName,cardTitle,contentsSide,imgUrl}) {

  return (
    <div data-aos='zoom-in-up' className={`image_card ${contentsSide}`} >
        <div className='card_image' >
          <img src={imgUrl}></img>
        </div>
        <div className='card_image_content'>
          <div className='card_image_name'>
              {cardName}
          </div>
          <div className='card_image_title'>
              {cardTitle}
          </div>
        </div>
        
    </div>
  )
}

CardImage.defaultProps={
    cardTitle: 'The Title',
    cardText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer enim ipsum, dictum at dolor eu, pharetra porttitor lacus.',
    contentsSide:'left'
}

export default CardImage