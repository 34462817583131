// import './CardContainer.css';
import './CardContainer2.css';
import CardImage from './Card_image';

import React from 'react'
import Card from './Card';

function CardContainer({cardsSide, cardContent, image}) {
  if(image === true){
    return(
      <div className={`card-container ${cardsSide}`}>
          {cardContent.map((card,index)=>{
            return(
              <CardImage key={index} cardName={card.name} cardTitle={card.title} imgUrl={card.img_url}contentsSide={cardsSide}></CardImage>
            )
          })}
      </div>
    )
  }else{
    return (  
      <div className={`card-container ${cardsSide}`}>
          {cardContent.map((card,index)=>{
            return(
              <Card key={index} cardTitle={card.name} cardText={card.description} contentsSide={cardsSide}></Card>
            )
          })}
      </div>
    )
  }
}

export default CardContainer