import React, { useRef} from 'react'
import "./HomeSection2.css";

function HomepageSection2({id,sectionTitle,sectionDesc,children,sectionContent}) {  
  const sectionTitleRef = useRef()
  // gsap.registerPlugin(ScrollTrigger)
  // useEffect(() => {
  //     const sectr = sectionTitleRef.current
  //     const titles = gsap.utils.toArray(sectr) //grouping all section titles in a "titles" array
  //     //set a context to be cleaned up once the dom has been updated
  //     let ctx = gsap.context(()=>{
  //       titles.forEach((title)=>{ //foreach title in the titles array, we will create a scroll trigger animation
  //         gsap.to(title, {
  //           scrollTrigger:{
  //             trigger: title,
  //             start: 'center center',
  //             end: '+=65%',
  //             scrub:1
  //           },
  //           width: '100%'
  //         })
  //         gsap.set(title, {width:'20%'})
  //       })
  //     })
  //     //returning a cleanup of the context
  //     return ()=>ctx.revert();
  // }, [])
  
  
  return (
    <div id={id} className="section_2">
      <div id={`${id}_section_container2`} className="section_container_2">
        <div className="section_content">
          <div ref={sectionTitleRef} className="section_title">{sectionTitle}</div>
          <div data-aos={`slide-right`} className="section_description">
            <h1>{sectionDesc}</h1> 
          </div>
          <div data-aos={`slide-right`} className="section_text">
            <p>{sectionContent}</p>
          </div>
        </div>
        <div className="section2_children">
          {children}
        </div>
      </div>
    </div>
    
  )
}

export default HomepageSection2