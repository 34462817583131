import React, {useEffect, useRef} from 'react';
import CardContainer from '../components/CardContainer';
// import Hero2 from '../components/Hero2';
import Hero3 from '../components/Hero3';
import "./Home.css";
import HomepageSection2 from '../components/HomepageSection2';
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MailchimpForm from '../components/Popup';
import Cookies from 'js-cookie';
import CookieConsent from 'react-cookie-consent';

function Home() {
  const theproject={
    sectionTitle: 'The Project',
    content: {
      text: "Imagine owning a digital collection in the Metaverse? \nDo you want to be part of the future?\nNow’s the time to invest in an awe-inspiring project which will bring licensed digital collectables, aka collectable Non-Fungible Tokens, or cNFTs, into the Metaverse. Imagine a collection without limits, a collection with unlimited space at a click of a button.",
      subSection:[
        {title: "What makes us \ndifferent ?", text: "Our cNFTs will be a reachable and realistic goal for collectors to purchase, trade and carry with you anytime, anywhere. We are investing in powerful tech to offer collectors, gamers, book lovers, dynamic investors, NFT enthusiasts, movie buffs and comic book fans, direct access to a futuristic token-based universe. Our tech expertise and business acumen combined with our passion and dedication will make Relic Mint one of the most flourishing projects of this decade."},
        {title: "How it works", text: "Our cNFTs will be a reachable and realistic goal for collectors to purchase, trade and carry with you anytime, anywhere. We are investing in powerful tech to offer collectors, gamers, book lovers, dynamic investors, NFT enthusiasts, movie buffs and comic book fans, direct access to a futuristic token-based universe. Our tech expertise and business acumen combined with our passion and dedication will make Relic Mint one of the most flourishing projects of this decade."}
      ],
    }
  }
  const difference={
    sectionTitle: 'Standing out',
    content:{
      text: "Our cNFTs will be a reachable and realistic goal for collectors to purchase, trade and carry with you anytime, anywhere. We are investing in powerful tech to offer collectors, gamers, book lovers, dynamic investors, NFT enthusiasts, movie buffs and comic book fans, direct access to a futuristic token-based universe. Our tech expertise and business acumen combined with our passion and dedication will make Relic Mint one of the most flourishing projects of this decade."
    }
  }
  const theConcepts={
    sectionTitle: 'The Concepts',
    content:{
      text: 'Our team at Relic Mint aims to partner with reputable and mainstream; TV, movie, comics, video game and book franchises to create timeless cNTFs of your favourite characters. Known collectable concepts such as “Mint in box”, “Character Autograph”, “Limited Edition” and “Uniquely flawed” will be exclusively introduced. Licensing, coupled with consistency in composition and design, will guarantee a quality valued investment for both rookie and experience investors',
      subContent:
      [
        {
          name: "Mint in Box",
          description: "A visualisation of how a prisitine figurine would look like in its original state. Sealed and preserved in a box"
        },
        {
          name: "Character autographer",
          description: "It gets even better; it will additionally be possible to have your character digitally autographed on it's box. Naturally this is likely to increase its value"
        },
        {
          name: "Limited Edition",
          description: "Owning a limited-edition cNFT will certainly set you apart from other collectors. Its rarity will add value and shine a become a distinctive grail in your collection"
        },
        {
          name: "Chase figure",
          description: "A collectors' terminology, meaning varied or hard to find, typically higher in value due to its rarity. Randomly generated cNFTs produced differently than the rest of the collection, based on a finite set of possible changes"
        }
      ]
    }
  };

  const theTeam={
    sectionTitle: 'The Concepts',
    content:{
      text: 'A team of trained ninjas working together to deliver a marketplace full of your favorite characters',
      subContent:[  
        {
          name: 'Joseph Matthew Muscat',
          title: 'CEO',
          img_url: './media/team_slabs/joe_m_slab.png'
        },
        {
          name: 'Mario Attard',
          title: 'COO',
          img_url: './media/team_slabs/mario_slab.png'
        },
        {
          name: 'Joe Borg',
          title: 'CTO',
          img_url: './media/team_slabs/joey_slab.png'
        },
        {
          name: 'Peter Magro',
          title: 'CDI',
          img_url: './media/team_slabs/peter_slab.png'
        },
        {
          name: 'Andre Lapira',
          title: 'CIO',
          img_url: './media/team_slabs/lapira_slab.png'
        },
        {
          name: 'Oliver Baldacchino',
          title: 'Research and Analyst',
          img_url: './media/team_slabs/oliver_slab.png'
        },
      ]
    }

  }

  gsap.registerPlugin(ScrollTrigger);
  const sloganTextRef = useRef(null)
  useEffect(() => {
    const ref_sloganText = sloganTextRef.current
    let ctx = gsap.context(()=>{ //used for cleanup in the effect's return statment
      gsap.to(ref_sloganText,{
        scrollTrigger:{
          trigger: ref_sloganText,
          start: 'center center',
          end: '+=500',
          pin: '#slogan_container',
          pinSpacing: true,
          scrub: 1
        },
        fontSize: '+=10em',
        transformOrigin: 'bottom left'
      })
      gsap.from(ref_sloganText,{delay:0,fontSize:'5em'})
      gsap.set(ref_sloganText,{fontSize:'5em'})
    })

    return ()=>ctx.revert(); //cleaning up the greensock animations
   
    
  })

  return (

      <div>
          {/* <Hero bgImgUrl={'test'} smallText={'Welcome to the world of collectible'} largeText={'NFTs'} ></Hero> */}
          <Hero3 bgImgUrl={'test'} smallText={'Welcome to the world of collectable'} largeText={'NFTs'} ></Hero3>
          {/* <Announcement title="Subscribe now and benefit"></Announcement> */}
          <MailchimpForm trigger={Cookies.get('__rm_nl_sub') ? false : true} mainText='Get 20% off your first order' subText='By simply subscribing to our newsletter and keep updated with our sick collections' ></MailchimpForm>
          <div className="home_body" id="home_body">
            <div id="slogan_container">
              <div id="slogan_inner">
                <h1 ref={sloganTextRef}>Licensable<br/> Tradable<br/> Collectable</h1>
              </div>
            </div>
            <HomepageSection2 id="project" sectionDesc="Unlocking true potential" sectionTitle="The project" sectionContent={theproject.content.text}></HomepageSection2>
            <HomepageSection2 id="team" sectionDesc="Who are we ?" sectionTitle="The team" sectionContent={theTeam.content.text}>
              <CardContainer cardsSide='center' cardContent={theTeam.content.subContent} image={true}></CardContainer>
            </HomepageSection2>
            <HomepageSection2 id="difference" sectionDesc="What makes us different" sectionTitle={difference.sectionTitle} sectionContent={difference.content.text}></HomepageSection2>
            <HomepageSection2 id="concepts" sectionDesc="How it works" sectionTitle="The concepts" sectionContent={theConcepts.content.text} contentFlow={'column'}>
                <CardContainer cardsSide='center' cardContent={theConcepts.content.subContent}></CardContainer>
            </HomepageSection2>
          </div>  
          <CookieConsent>We'll need you to consent about collecting some information about you. To ensure transparency we invite you to read all about it <a style={{color:'white', textDeoration: 'underline'}} href="https://www.relicmint.com/api/privacy-policy">here</a></CookieConsent>
      </div>
  )
  
}

export default Home