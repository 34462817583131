import './Popup.css';
import React, { useEffect, useState }  from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Cookies from 'js-cookie';

const Popupcontainer = ({ mainText, subText, status, message, onValidated }) => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [visible, setVisible] = useState(true)
    const handleClick=()=>{
        setVisible(false)
    }

    useEffect(() => {
        if(status === "success") {
            setTimeout(()=>{
                clearFields();
                handleClick()
            },1000)
            Cookies.set('__rm_nl_sub', true, {expires: 30})
        }
    }, [status])

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
    }
    
    const emailHandler = e=>setEmail(e.target.value)
    const firstNameHandler = e=>setFirstName(e.target.value)
    const lastNameHandler = e=>setLastName(e.target.value)
    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        firstName &&
        lastName &&
        email.indexOf("@") > -1 &&
        onValidated({
            MERGE0: email,
            MERGE1: firstName,
            MERGE2: lastName,
        });
    }
    return(visible) ? (
        <form onSubmit={(e) => handleSubmit(e)} className='popup'>
            <div className="popup-inner">
                <div className="popup_close">
                    <i onClick={handleClick} className="fas fa-times"></i>
                </div>
                <div className="popup-text">
                    <h1 className="popup_main_text">{mainText}</h1>
                    <h3 className="popup_sub_text">{subText}</h3>
                    <div className="popup_status">
                    {status === "sending" && (
                        <div className="mc__alert mc__alert--sending">
                            sending...
                        </div>
                    )}
                    {status === "error" && (
                        <div 
                            className="mc__alert mc__alert--error"
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )}
                    {status === "success" && (
                        <div
                            className="mc__alert mc__alert--success"
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )}
                    </div>
                </div>
                <div className="form_fields">
                    <input className="popup_email_field" type="email" onChange={emailHandler} value={email} placeholder='email'></input>
                    <input className="popup_email_field" type="text" onChange={firstNameHandler} value={firstName} placeholder='First Name'></input>
                    <input className="popup_email_field" type="text" onChange={lastNameHandler} value={lastName} placeholder='Last Name'></input>
                    <div className="popup_btn_container">
                        <button className="popup_button">Register</button>
                    </div>
                </div>
            </div>
        </form>
    ) : ''
}

const MailchimpForm = props => {
    const postUrl = `https://relicmint.us9.list-manage.com/subscribe/post?u=957db70a8fc52bdcf6d32727c&id=7d24556ce6`

    return(props.trigger) ? (
        <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
                <Popupcontainer
                    status={status} 
                    message={message}
                    onValidated={formData => subscribe(formData)}
                    mainText={props.mainText}
                    subText={props.subText} 
                />
            )}
        />
    ) : ''
}

export default MailchimpForm