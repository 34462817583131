import React from 'react'
import "./burger.css"

function Burger({clicked}) {
  return (
    <div className={clicked ? `menu-btn clicked` : `menu-btn`}>
        <div className="menu-btn__burger"></div>
    </div>
  )
}

export default Burger