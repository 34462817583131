import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import './components/general.css';
import Home from './Pages/Home';
import AOS from 'aos';
import "aos/dist/aos.css"
import React,{ useEffect } from 'react';
import Footer from './components/Footer';
function App() {

  useEffect(() => {
    AOS.init({
      once: true
    });
  }, []);
  

  const menuItems=[
    {name: 'project', url: '#project'},
    {name: 'difference', url:'#difference'},
    {name: 'team', url:'#team'},
    {name: 'concepts', url:'#concepts'}
  ]

  const footerNavItems=[
    {name: 'Sitemap', url:'/token'},
    {name: 'Privacy Policy', url:'/privacy-policy'}
  ]

  return (
    
    <div className="App">
        <BrowserRouter>
          <Navbar menuItems={menuItems}></Navbar>
          <Routes>
            <Route path='/' element={<Home/>}/>
            {menuItems.map((item)=>{
              <Route path={item.url}/>
            })}
            {footerNavItems.map((items, index)=>{
              return (<Route path={items.url} key={index}/>)
            })}
          </Routes>
          
          <Footer menuItems={menuItems}></Footer>
        </BrowserRouter>
    </div>
  );
}

export default App;
