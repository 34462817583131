import './Footer.css';
import React from 'react'
import { HashLink } from 'react-router-hash-link';
import Logo from './Logo';

function Footer({menuItems}) {
  return (
    <div className='footer'>
        <div className='footer-container'>
            <Logo></Logo>
            <div className='footer-newsletter'></div>
            <div className='footer-navigation'>
                <div className='footer-navigation-title'>
                    Navigation
                </div>
                <div className='footer-navigation-content'>
                    {
                        menuItems.map((item, index)=>{
                            return <HashLink className='footer-navigation-link' to={item.url} key={index}>{item.name}</HashLink>
                        })
                    }
                    <div className='socials'>
                        <i className="fab fa-instagram"></i>
                        <i className="fab fa-facebook-f"></i>
                    </div>
                </div>
            </div>
        </div>
        <div className='footer-ribbon'>
            <div>Privacy Policy</div>
            <div>Sitemap</div>
            <div>VariableTwo ©</div>
        </div>
    </div>
  )
}

export default Footer