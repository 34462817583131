import React from 'react'
import { Link } from 'react-router-dom'
import './Logo.css'

function Logo() {
  return (
    <>
        <div className='logo-container'>
            <div className='logo'>
                <Link to='/'>
                    <img src={`./media/rm-logo.png`}></img>
                </Link>
            </div>
        </div>
    </>
  )
}

export default Logo